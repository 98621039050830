<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reporte Cumplimiento</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Reporte Cumplimiento</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="fecha">Fecha</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      :class="
                        $v.filtros.fecha.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nominacion</label>
                    <v-select
                      :class="[
                        $v.filtros.nominacion_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="nominacion"
                      placeholder="Nominacion"
                      label="nombre"
                      :options="listasForms.nominaciones"
                      @input="seleccionarNominacion()"
                    ></v-select>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Transportadora</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Razon social"
                      label="razon_social"
                      :options="listasForms.empresas"
                      @input="seleccionarTransportadora()"
                    ></v-select>
                  </div>
                  <!-- <div class="form-group col-md-2">
                    <label>Campo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio"
                      placeholder="Sitio"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="seleccionarSitio()"
                    ></v-select>
                  </div> -->

                  <div class="col-md-1">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn bg-navy mt-4"
                        @click="getIndex()"
                        :disabled="$v.filtros.$invalid"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger mt-4"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Tabla"
                    data-toggle="tab"
                    href="#Tabla"
                    >Detalle</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Grafica"
                    data-toggle="tab"
                    href="#Grafica"
                    >General</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Tabla">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-body table-responsive p-0">
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              class="
                                table
                                table-bordered
                                table-striped
                                table-hover
                                table-sm
                              "
                              style="font-size: 0.8em"
                            >
                              <thead class="thead text-center">
                                <tr>
                                  <th>
                                    Sitio
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="filSitio"
                                    />
                                  </th>
                                  <th>
                                    Empresa
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="filEmpresa"
                                    />
                                  </th>
                                  <th>
                                    Nominados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('nominados')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.nominados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Adicionales
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('adicionales')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.adicionales
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Nominados + Adicionales
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarDatos('nominado_adicional')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.nominado_adicional
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Solicitados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('solicitados')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.solicitados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Oferta Adicional
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('oferta_adicional')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.oferta_adicional
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Postulados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('postulados')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.postulados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Cargados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('cargados')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.cargados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Solicitado vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarDatos('solicitado_cargado')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.solicitado_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Postulado vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('postulado_cargado')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.postulado_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Nominado vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarDatos('nominado_cargado')"
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.nominado_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    (Nominado + Adc) vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarDatos(
                                          'nominado_adicional_cargado'
                                        )
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenAscendente.nominado_adicional_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="text-nowrap">
                                <tr v-for="item in datosDet" :key="item.id">
                                  <td>{{ item.sitio }}</td>
                                  <td>{{ item.empresa }}</td>
                                  <td class="text-right">
                                    {{ item.nominados }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.adicionales }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.nominado_adicional }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.solicitados }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.oferta_adicional }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.postulados }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.cargados }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.solicitado_cargado >= 90 &&
                                      item.solicitado_cargado <= 100
                                        ? 'table-success'
                                        : item.solicitado_cargado >= 50 &&
                                          item.solicitado_cargado < 90
                                        ? 'table-warning'
                                        : item.solicitado_cargado > 100
                                        ? 'table-warning'
                                        : 'table-danger'
                                    "
                                  >
                                    {{ item.solicitado_cargado }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.postulado_cargado >= 90 &&
                                      item.postulado_cargado <= 100
                                        ? 'table-success'
                                        : item.postulado_cargado >= 50 &&
                                          item.postulado_cargado < 90
                                        ? 'table-warning'
                                        : item.postulado_cargado > 100
                                        ? 'table-warning'
                                        : 'table-danger'
                                    "
                                  >
                                    {{ item.postulado_cargado }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.nominado_cargado >= 80
                                        ? 'table-danger'
                                        : item.nominado_cargado >= 50 &&
                                          item.nominado_cargado < 80
                                        ? 'table-warning'
                                        : item.nominado_cargado == 0
                                        ? 'table-warning'
                                        : 'table-success'
                                    "
                                  >
                                    {{ item.nominado_cargado }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.nominado_adicional_cargado >= 80
                                        ? 'table-danger'
                                        : item.nominado_adicional_cargado >=
                                            50 &&
                                          item.nominado_adicional_cargado < 80
                                        ? 'table-warning'
                                        : item.nominado_adicional_cargado == 0
                                        ? 'table-warning'
                                        : 'table-success'
                                    "
                                  >
                                    {{ item.nominado_adicional_cargado }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Grafica">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-body table-responsive p-0">
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              class="
                                table
                                table-bordered
                                table-striped
                                table-hover
                                table-sm
                              "
                              style="font-size: 0.8em"
                            >
                              <thead class="thead text-center">
                                <tr>
                                  <th>
                                    Empresa
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="filEmpresaG"
                                    />
                                  </th>
                                  <th>
                                    Nominados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarGeneral('nominados')"
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.nominados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Adicionales
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarGeneral('adicionales')"
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.adicionales
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Nominados + Adicionales
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarGeneral('nominado_adicional')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.nominado_adicional
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Solicitados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarGeneral('solicitados')"
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.solicitados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Oferta Adicional
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarGeneral('oferta_adicional')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.oferta_adicional
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Postulados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarGeneral('postulados')"
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.postulados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Cargados
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="ordenarGeneral('cargados')"
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.cargados
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Solicitado vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarGeneral('solicitado_cargado')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.solicitado_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Postulado vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarGeneral('postulado_cargado')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.postulado_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    Nominado vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarGeneral('nominado_cargado')
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.nominado_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                  <th>
                                    (Nominado + Adc) vs Cargado
                                    <button
                                      class="btn btn-xs btn-dark"
                                      @click="
                                        ordenarGeneral(
                                          'nominado_adicional_cargado'
                                        )
                                      "
                                    >
                                      <i
                                        :class="
                                          ordenGeneral.nominado_adicional_cargado
                                            ? 'fas fa-sort-amount-down-alt'
                                            : 'fas fa-sort-amount-up-alt'
                                        "
                                      ></i>
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="text-nowrap">
                                <tr v-for="item in datosGeneral" :key="item.id">
                                  <td>{{ item.empresa }}</td>
                                  <td class="text-right">
                                    {{ item.nominados }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.adicionales }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.nominado_adicional }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.solicitados }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.oferta_adicional }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.postulados }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.cargados }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.solicitado_cargado >= 90
                                        ? 'table-success'
                                        : item.solicitado_cargado >= 50 &&
                                          item.solicitado_cargado < 90
                                        ? 'table-warning'
                                        : 'table-danger'
                                    "
                                  >
                                    {{ item.solicitado_cargado }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.postulado_cargado >= 90
                                        ? 'table-success'
                                        : item.postulado_cargado >= 50 &&
                                          item.postulado_cargado < 90
                                        ? 'table-warning'
                                        : 'table-danger'
                                    "
                                  >
                                    {{ item.postulado_cargado }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.nominado_cargado >= 80
                                        ? 'table-danger'
                                        : item.nominado_cargado >= 50 &&
                                          item.nominado_cargado < 80
                                        ? 'table-warning'
                                        : item.nominado_cargado == 0
                                        ? 'table-warning'
                                        : 'table-success'
                                    "
                                  >
                                    {{ item.nominado_cargado }}
                                  </td>
                                  <td
                                    class="text-right"
                                    :class="
                                      item.nominado_adicional_cargado >= 80
                                        ? 'table-danger'
                                        : item.nominado_adicional_cargado >=
                                            50 &&
                                          item.nominado_adicional_cargado < 80
                                        ? 'table-warning'
                                        : item.nominado_adicional_cargado == 0
                                        ? 'table-warning'
                                        : 'table-success'
                                    "
                                  >
                                    {{ item.nominado_adicional_cargado }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers } from "vuelidate/lib/validators";
//import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "ReporteCumplimientoIndex",
  components: {
    Loading,
    // pagination,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        fecha: null,
        nominacion_id: null,
      },
      filEmpresa: "",
      filSitio: "",
      filEmpresaG: "",
      ordenAscendente: {
        nominados: true,
        nominado_adicional: true,
        adicionales: true,
        solicitados: true,
        oferta_adicional: true,
        postulados: true,
        cargados: true,
        solicitado_cargado: true,
        postulado_cargado: true,
        nominado_cargado: true,
        nominado_adicional_cargado: true,
      },

      ordenGeneral: {
        nominados: true,
        nominado_adicional: true,
        adicionales: true,
        solicitados: true,
        oferta_adicional: true,
        postulados: true,
        cargados: true,
        solicitado_cargado: true,
        postulado_cargado: true,
        nominado_cargado: true,
        nominado_adicional_cargado: true,
      },
      transportadora: null,
      sitio: null,
      nominacion: null,
      datos: [],
      general: [],
      listasForms: {
        estados: [],
        sitios: [],
        productos: [],
        empresas: [],
        tipos_producto: [],
        tipos_solicitud: [],
        nominaciones: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha: { required },
      nominacion_id: { required },
    },
  },
  computed: {
    datosDet() {
      if (this.datos) {
        return this.datos.filter((dato) => {
          const searchSitio = this.filSitio.toLowerCase();
          const searchEmpresa = this.filEmpresa.toLowerCase();

          const sitio = dato.sitio.toLowerCase();
          const empresa = dato.empresa.toLowerCase();

          return sitio.includes(searchSitio) && empresa.includes(searchEmpresa);
        });
      } else {
        return this.datos;
      }
    },

    datosGeneral() {
      if (this.general) {
        return this.general.filter((dato) => {
          const searchEmpresa = this.filEmpresaG.toLowerCase();

          const empresa = dato.empresa.toLowerCase();

          return empresa.includes(searchEmpresa);
        });
      } else {
        return this.general;
      }
    },
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/reportes/informeCumplimiento?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.datos = response.data.detalle;
          this.general = response.data.totales;
        })
        .catch(function(error) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiar() {
      this.transportadora = null;
      this.sitio = null;
      this.filtros.fecha = "";
      this.filtros.transportadora_id = "";
      this.filtros.sitio_id = "";
      this.filtros.nominacion_id = "";
      this.filtros.estado = "";
    },

    ordenarDatos(campo) {
      this.ordenAscendente[campo] = !this.ordenAscendente[campo];
      this.datos.sort((a, b) => {
        if (this.ordenAscendente[campo]) {
          return a[campo] - b[campo]; // Orden ascendente
        } else {
          return b[campo] - a[campo]; // Orden descendente
        }
      });
    },

    ordenarGeneral(campo) {
      this.ordenGeneral[campo] = !this.ordenGeneral[campo];
      this.general.sort((a, b) => {
        if (this.ordenGeneral[campo]) {
          return a[campo] - b[campo]; // Orden ascendente
        } else {
          return b[campo] - a[campo]; // Orden descendente
        }
      });
    },

    getEstados() {
      axios.get("/api/lista/65").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTransportadora() {
      this.filtros.transportadora_id = this.transportadora
        ? this.transportadora.id
        : null;
    },

    seleccionarSitio() {
      this.filtros.sitio_id = this.sitio ? this.sitio.id : null;
    },

    seleccionarNominacion() {
      this.filtros.nominacion_id = this.nominacion ? this.nominacion.id : null;
    },

    async getNominaciones() {
      await axios
        .get("/api/hidrocarburos/nominacion/lista")
        .then((response) => {
          this.listasForms.nominaciones = response.data;
        });
    },
  },

  mounted() {
    //this.getIndex();
    this.getEstados();
    this.buscarTransportadora();
    //this.buscarProductos();
    this.buscarSitios();
    this.getNominaciones();
  },
};
</script>
